.icons {
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border: solid;
  border-color: rgb(136, 136, 136);
  border-width: 0.5px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.33) 35%, rgba(255, 255, 255, 0.12) 100%);
}

.icons:hover {
  transition: transform 0.1s ease-in-out;
  transform: scale(0.92);
}
