.todo-home {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 89.6vh;
  height: 100%;
}

.todo-main {
  /* background-color: magenta; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 450px;
  height: auto;
  margin-top: 5%;
  padding-bottom: 50px;
}

.todo-progress {
  /* background-color: green; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.my-progress-bar {
  width: 175px;
  margin: 5px 0px 18px 30px;
}

.todo-items-container {
  /* background-color: brown; */
  overflow: auto;
  max-height: 360px;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.todo-input-container {
  /* background-color: tomato; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 300px;
  width: 100%;
  max-width: 450px;
  padding: 10px 50px 0px 50px;
}

.todo-input {
  display: flex;
  width: 90%;
  min-width: 250px;
  max-width: 300px;
  padding: 2px 0px 2px 10px;
}

.add-icon {
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border: solid;
  border-color: green;
  border-width: 0.5px;
  border-radius: 10px;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.075s ease-in-out;
}

.add-icon:hover {
  transition: transform 0.075s ease-in-out;
  transform: scale(0.92);
}

@media only screen and (max-width: 800px) {
  .todo-home {
    width: 100%;
    justify-content: flex-start;
    padding-top: 5%;
  }

  .todo-items-container {
    max-height: 50vh;
  }

  .todo-input {
    margin-right: 10px;
  }
}
