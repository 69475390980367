.container {
  display: flex;
  width: 325px;
  height: 175px;
  padding: 15px;
  padding-top: 10px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.top {
  /* background-color: red; */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.secondary_text {
  color: rgb(184, 184, 184);
  display: flex;
  justify-content: space-between;
}
