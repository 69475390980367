.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 1000;
  min-height: 6vh;
  width: 100%;
  /* height: 6vh; */
  background-color: #21252b;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 6px 16px;
}

.nav-item .dropdown-menu {
  background: #343a40;
}

.nav-item:hover {
  color: rgba(255,255,255,.75);
  background-color: transparent;
}

.dropdown-item{
  margin: 4px 0px;
  padding-top: 0;
  padding-bottom: 0;
}

.dropdown-item:hover {
  color: rgba(255,255,255,.75);
  background-color: transparent;
  text-decoration: none;

}

.nav-link:hover{
  text-decoration: none;
  color: rgba(255,255,255,.75);
}
