.block,
.block_reversed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 35px;
  width: 78vw;
  max-width: 315px;
  /* width: 95%; */
  height: 100%;
  height: 70vh;
  max-height: 410px;
  position: relative;
  background: linear-gradient(30deg, rgb(20, 20, 20), rgb(35, 35, 35));
  font-family: "Raleway";
}

.block:before,
.block:after,
.block_reversed:before,
.block_reversed:after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  /* background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094); */
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  background-size: 500%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
}

.block:before,
.block:after {
  animation: steam 20s linear infinite;
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
}

.block_reversed:before,
.block_reversed:after {
  animation: steam_reversed 20s linear infinite;
  background: linear-gradient(
    60deg,
    #6fba82
    #07b39b,
    #1098ad,
    #5073b8,
    #a166ab,
    #ef4e7b,
    #f37055,
    #f79533,
  );
}

.block:after,
.block_reversed:after {
  filter: blur(15px);
}

@keyframes steam {
  0% {
    background-position: 0% 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes steam_reversed {
  0% {
    background-position: 0% 0;
  }
  50% {
    background-position: 150% 0;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* https://codepen.io/Huhtamaki/pen/GPzwPY */
/* https://codepen.io/mike-schultz/pen/NgQvGO */
