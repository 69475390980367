.todo-item-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 450px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 7px;
  padding: 7px 10px;
  margin-bottom: 10px;
  background-color: rgba(46, 0, 131, 0.04);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 8px, rgba(0, 0, 0, 0.12) 0px 5px 5px;
}

.completed {
  text-decoration: line-through !important;
  opacity: 0.25 !important;
}

.dragging {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
  border-style: dashed;
  border-width: 4px;
  /* max-width: 210px; */
}

.droppable {
  background-color: rgba(255, 255, 255, 0.13);
}

.todo-item-text,
.todo-item-text-completed {
  margin-right: 25px;
  word-wrap: break-word;
  max-width: 95%;
  cursor: text;
  padding: 5px 0px;
}

.todo-item-text {
  transition: transform 0.75s ease-in-out;
}

.todo-item-text:hover {
  transition: transform 0.25s ease-in-out;
  transform: scale(0.94);
}

textarea.todo-item {
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}

textarea.todo-item:focus {
  outline: none;
}

@media only screen and (max-width: 800px) {
  .todo-item-text,
  .todo-item-text-completed {
    margin-right: 20px;
  }
}
