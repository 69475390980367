.rotate {
  animation: rotate 200ms ease-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  /* 50% {
    transform: rotate(220deg);
} */
  100% {
    transform: rotate(180deg);
  }
}
