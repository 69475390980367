.container {
  margin-top: calc(50px + 2%);
  display: flex;
  justify-content: space-evenly;
  min-width: 450px;
  width: 40%;
  /* background-color: tomato; */
}

.badges {
  padding: 5px;
  transition: 0.25s ease-in-out;
  filter: drop-shadow(4px 5px 2px rgba(0, 0, 0, 0.6));
}

.badges:hover {
  opacity: 0.66;
  transform: scale(0.92);
}

@media only screen and (max-width: 700px) {
  .container {
    margin-top: calc(25px + 2%);
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: auto;
  }
}
