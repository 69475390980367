.formula-main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-height: 94vh;
  height: 100%;
  padding-bottom: 10%;
}

.formula-selection {
  /* background-color: pink; */
  display: flex;
  margin-left: 3%;
  margin-top: 3%;
}

.formula-tng {
  /* background-color: teal; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2%;
  margin-left: 3%;
  width: 87%;
}

.formula-left {
  /* background-color: blanchedalmond; */
  display: flex;
  min-width: 700px;
  max-width: 50%;
}

.formula-right {
  /* background-color: tomato; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 375px;
  justify-content: center;
  align-items: center;
}

.driver-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 95%;
  padding-right: 25px;
  /* padding: 25px; */
}

.driver-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 90%;
  border-style: dashed;
  border-width: 5px;
  border-color: rgba(120, 120, 120, 0.5);
  margin-top: 25px;
}

@media only screen and (max-width: 1600px) {
  .formula-tng {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 1000px) {
  .formula-tng {
    width: 100%;
    margin-left: 0;
    align-items: center;
  }
  .formula-selection {
    flex-direction: column;
    align-items: flex-start;
    margin: 5%;
  }
  .formula-left {
    min-width: 90%;
    max-width: 90%;
  }
  .formula-right {
    width: 90%;
    margin-bottom: 20px;
  }
  .driver-info {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    margin-top: 35px;
  }
}
