.main {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  min-width: calc(350px + 10%);
}

.logo{
  transition: 0.2s ease-in-out;
  padding: 3px
}

.logo:hover{
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 28px, rgba(0, 0, 0, 0.22) 5px 8px 7px;
  border-radius: 12px;
}

.first_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.second_section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.first_section > img, .second_section > img{
  filter: drop-shadow(4px 5px 2px rgba(0, 0, 0, 0.6));
}

@media only screen and (max-width: 700px) {
  .main {
    min-width: 90%;
  }
}
