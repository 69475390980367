.base {
  margin-top: 2%;
  flex-direction: column;
  display: flex;
  width: 70%;
  /* max-width: 1000px; */
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 10px 1.5% 15px;
  background-color: #282c34;
  border: 1px solid rgb(153, 153, 153);
  box-shadow: rgba(0, 0, 0, 0.45) 0px 15px 15px;
  transition: transform 0.15s ease-in-out;
}

@media (hover: hover) {
  .base:hover {
    transform: scale(0.97);
  }
}

.main {
  /* background-color: brown; */
  width: 100%;
}

.exp_text {
  margin-left: 120px;
  margin-top: 20px;
  width: 80%;
}

.exp_company_logo {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 8px 8px;
}

@media only screen and (max-width: 800px) {
  .base {
    width: 90%;
  }
  .exp_text {
    margin-left: 0;
    width: 100%;
  }
}
