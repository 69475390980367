.stocks-home {
  /* background-color: rgba(255, 99, 71, 0.192); */
  width: 100%;
  height: 89.5vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 25px;
  padding-top: 2%;
  padding-bottom: 25px;
}

.stocks-main {
  /* background-color: rgba(0, 0, 255, 0.212); */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.stocks-search {
  /* background-color: teal; */
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.history-chart {
  padding-left: 50px;
  width: 80%;
}

.stock-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 300px;
}

.stock-chips > * {
  margin-top: 5px;
}

.price-range-chart {
  width: 250px;
  height: 350px;
  margin-top: 5px;
  margin-right: 20px;
}

@media only screen and (max-width: 1200px) {
  .stocks-home {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    /* background-color: blue; */
    height: auto;
  }

  .history-chart {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    margin-bottom: 35px;
    margin-left: -40px;
  }
}
