.footer-main {
    margin: 0;
    padding: 10px 0px 10px 0px;
    /* background-color: #21252b; */
    background-color: #282c34;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: auto;
    height: 100%;
}