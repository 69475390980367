:root {
    --accentColor: #7a3c97;
  }

 /* Highlight color */
::-moz-selection {
    background: var(--accentColor);
}
::-webkit-selection {
  background: var(--accentColor);
}
::selection {
  background: var(--accentColor);
}

body{
    color: white;
    overflow: overlay;
    background-color: #282c34;
}

 /* Links color */
a:link {
    color: rgb(23, 184, 135);
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: rgb(23, 184, 135);
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: var(--accentColor);
    background-color: transparent;
    text-decoration: underline;
}

a:active {
    color: white;
    background-color: transparent;
    text-decoration: underline;
}

 /* Scrollbar */
*::-webkit-scrollbar {
    width: .5rem;
}

*::-webkit-scrollbar-track {
    border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(23, 184, 135, .5);
}


.app-main{
    background-color: #282c34;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 94vh;
}

.app-content{
    width: 100%;
    max-width: 400px;
    margin-top: -6vh;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

