@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 94vh;
  height: 100%;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s; /* Firefox */
  -webkit-animation: fadein 0.5s; /* Safari and Chrome */
  -o-animation: fadein 0.5s; /* Opera */
  /* padding-bottom: 10%; */
}

.home-bio {
  margin-top: 3%;
  min-width: min(90vw, 375px);
  min-height: 500px;
  width: 25%;
  border: 2px solid white;
  border-spacing: 15px 50px;
  background-color: #21252b;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 15px 25px;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.divider {
  border-bottom: 1px solid white;
  margin: 25px 0px;
}

.home-exp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #21252b;
  width: 100%;
  height: 100%;
  padding-top: 2%;
  padding-bottom: calc(50px + 3%);
}

.home-experience-text {
  color: white;
  width: auto;
  background-color: brown;
}

.home-projects-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: calc(25px + 2%);
  padding-bottom: 50px;
}

.home-projects-main {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
}

.home-certifications {
  padding-top: calc(25px + 2%);
  background-color: #21252b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: calc(50px + 5%);
}

.home-projects-alpha,
.home-projects-animation {
  transition: transform 0.15s ease-in-out;
}

.github-container {
  padding: 25px 20px 35px 15px;
  margin: 30px 0px 5px 0px;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.18);
  border-radius: 15px;
}

.home-alpha-projects-container {
  display: flex;
}

@media (hover: hover) {
  .home-projects-alpha:hover,
  .home-projects-animation:hover {
    transform: scale(0.97);
  }
}

@media only screen and (max-width: 1200px) {
  .home-projects-main {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .home-alpha-projects-container {
    flex-direction: column;
  }
}
